/*
  订单明细
*/

<template>
  <div>
    <a-form layout="inline">
      <a-form-item>
        <label>创建时间：</label>
        <a-range-picker v-model="insetData.createTime" @change="createChange" :disabledDate="disabledDate" size="small" />
      </a-form-item>
      <a-form-item>
        <label>报件时间：</label>
        <a-range-picker v-model="insetData.commitTime" @change="commitChange" :disabledDate="disabledDate" size="small" />
      </a-form-item>
      <a-form-item>
        <label>支付时间：</label>
        <a-range-picker v-model="insetData.payTime" @change="payChange" :disabledDate="disabledDate" :defaultValue="[moment(getCurrentMonthFirst, dateFormat), moment(getNowFormatDate, dateFormat)]" size="small" />
      </a-form-item>
      <a-form-item>
        <label>订单渠道：</label>
          <a-select placeholder="请选择" v-model="insetData.orderChannel" allowClear style="width: 150px" @change="orderChange">
          <a-select-option value="app">app</a-select-option>
          <a-select-option value="app_android">app_android</a-select-option>
          <a-select-option value="app_ios">app_ios</a-select-option>
          <a-select-option value="pc">pc</a-select-option>
          <a-select-option value="saas_app_offline">saas_app_offline</a-select-option>
          <a-select-option value="saas_app_online">saas_app_online</a-select-option>
          <a-select-option value="wechat">wechat</a-select-option>
          <a-select-option value="zgg">zgg</a-select-option>
          <a-select-option value="zgg_serve">zgg_serve</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>订单号：</label>
        <a-input placeholder="请输入内容" v-model="insetData.ordercode" allowClear size="small" style="width: 150px;" @change="orderCodeChange" />
      </a-form-item>
      <a-form-item>
        <label>合同号：</label>
        <a-input placeholder="请输入内容" v-model="insetData.paycode" allowClear size="small" style="width: 150px;" @change="payCodeChange" />
      </a-form-item>
      <a-form-item>
        <label>业务主体名称：</label>
        <a-input placeholder="请输入内容" v-model="insetData.bname" allowClear size="small" style="width: 150px;" @change="bnameChange" />
      </a-form-item>
      <a-form-item>
        <label>产品名称：</label>
        <a-input placeholder="请输入内容" v-model="insetData.threeType" allowClear size="small" style="width: 150px;" @change="productChange" />
      </a-form-item>
      <a-form-item>
        <label>客户类型：</label>
          <a-select placeholder="请选择" v-model="insetData.customerType" allowClear style="width: 150px" @change="customerChange">
          <a-select-option value="新成交客户">新成交客户</a-select-option>
          <a-select-option value="复购客户">复购客户</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>注册时间：</label>
        <a-range-picker v-model="insetData.registerTime" @change="registerChange" :disabledDate="disabledDate" size="small" />
      </a-form-item>
      <a-form-item>
        <label>首次成交日期：</label>
        <a-range-picker v-model="insetData.successTime" @change="firstChange" :disabledDate="disabledDate" size="small" />
      </a-form-item>
      <a-form-item>
        <label>分公司：</label>
          <a-select placeholder="请选择区域/分公司" v-model="insetData.filialeType" allowClear style="width: 200px" @change="filialeChange" @focus="filialeFocus">
          <a-select-option v-for="item in companyArr" :key="item">{{ item }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>小组：</label>
          <a-select placeholder="请选择小组" v-model="insetData.groupeType" allowClear style="width: 200px" @change="groupeChange">
          <a-select-option v-for="item in groupArr" :key="item.groupId">{{ item.groupName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>顾问：</label>
          <a-select placeholder="请选择顾问" v-model="insetData.counselorType" allowClear style="width: 200px" @change="counselorChange">
          <a-select-option v-for="item in counselorArr" :key="item.sysId">{{ item.nickName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>是否退款：</label>
          <a-select placeholder="请选择" v-model="insetData.refundIs" allowClear style="width: 150px" @change="refundChange">
          <a-select-option value="1">已支付</a-select-option>
          <a-select-option value="-2">已退款</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <label>退款时间：</label>
        <a-range-picker @change="refundTimeChange" v-model="insetData.refundTime" :disabledDate="disabledDate" size="small" />
      </a-form-item>

      <!--- 立案时间  ---->
      <a-form-item>
        <label>立案时间：</label>
        <a-range-picker @change="filingTimeChange" v-model="insetData.filingTime" :disabledDate="disabledDate" size="small" />
      </a-form-item>

      <a-form-item>
        <a-button type="primary" icon="search" @click="inquireClick('1')">查询</a-button>
        <a-button type="primary" icon="search" style="marginLeft: 15px;" @click="exportClick('2')">导出查询结果</a-button>
        <a-button style="marginLeft: 15px;" @click="insetClick('3')">重置</a-button>
        <a-button v-if="Number(userInfo.jobLevel) === 1000"  type="primary" style="marginLeft: 15px;" :disabled="!rowSelection.selectedRowKeys || rowSelection.selectedRowKeys.length <= 0"  @click="updateOrderType()">修改订单类型</a-button>
        <a-button   type="primary" style="marginLeft: 15px;" :disabled="!rowSelection.selectedRowKeys || rowSelection.selectedRowKeys.length <= 0"  @click="orderSharing()">订单分成</a-button>
      </a-form-item>
    </a-form>
    <!-- 表格部分 -->
    <div>
      <a-spin tip="加载中..." :spinning="loadingData">
        <a-table :columns="columns" size="middle" :row-selection="rowSelection" :dataSource="tableData" :pagination="false" :scroll="{ x: 5400 }" :bordered="true" rowKey="id" />
      </a-spin>
    </div>
    <!-- 分页部分 -->
    <div class="pageClass">
      <a-pagination :total="pageTotal" :showTotal="total => `共 ${pageTotal} 条`" showSizeChanger showQuickJumper @change="pageChange" @showSizeChange="pageSizePage" />
    </div>

    <!-- 修改订单类型 -->
    <a-modal
      v-model="visibleUpdateOrderType"
      title="修改订单类型"
      width="40%"
      :destroyOnClose="true"
      :closable="true"
      :footer="null"
    >
      <a-form-model
          ref="rule"
          :model="updateFormOrderType"
          :layout="updateFormOrderType.layout"
          v-bind="formItemLayout"
        >
      <div>
        <a-row>
          <a-col :span="24"   >
            <a-form-model-item label="订单类型:" :wrapper-col="{ span: 8, offset: 4 }" >
            <a-select
              show-search
              option-filter-prop="children"
              placeholder="请选择订单类型"
              v-model="updateFormOrderType.customerTypeNew"
              :filter-option="filterOption"
              :getPopupContainer="(triggerNode)=>{ return triggerNode.parentNode}"
              allowClear
            >
              <a-select-option value="新客户订单">新客户订单</a-select-option>
              <a-select-option value="老客户订单">老客户订单</a-select-option>
            </a-select>
            </a-form-model-item>
          </a-col>

        </a-row>

        <a-row>
          <a-col :span="24" style="text-align:center"  >
              <a-button type="primary" :loading="buttonLoading" @click="onUpdateOrderType()" >保存</a-button>
          </a-col>
        </a-row>

      </div>
      </a-form-model>
    </a-modal>

    <!-- 订单分成 -->
    <a-modal
      v-model="visibleUpdateOrderSharing"
      title="修改订单分成"
      width="40%"
      :destroyOnClose="true"
      :closable="true"
      :footer="null"
    >
      <a-form-model
          ref="rule"
          :model="updateFormOrderType"
          :layout="updateFormOrderType.layout"
          v-bind="formItemLayout"
        >
      <div>
        <a-row>
          <a-col :span="24"   >
            <a-form-model-item label="分成顾问:" :wrapper-col="{ span: 8, offset: 4 }" >
            <a-select
              show-search
              option-filter-prop="children"
              placeholder="请选择分成顾问"
              v-model="updateFormOrderType.shareGwId"
              :filter-option="filterOption"
              :getPopupContainer="(triggerNode)=>{ return triggerNode.parentNode}"
              allowClear
            >
              <a-select-option v-for="item in gwList" :key="item.userid">{{ item.username }}</a-select-option>
            </a-select>
            </a-form-model-item>

          </a-col>

        </a-row>

        <a-row>
          <a-col :span="24"   >
            <a-form-model-item label="分成比例:" :wrapper-col="{ span: 8, offset: 4 }" >
                <a-input-number  v-model="updateFormOrderType.shareScale" :min="1" :max="100" />%
            </a-form-model-item>

          </a-col>

        </a-row>

        <a-row>
          <a-col :span="24" style="text-align:center"  >
              <a-button type="primary" :loading="buttonLoading" @click="submitOrderSharing()" >保存</a-button>
          </a-col>
        </a-row>

      </div>
      </a-form-model>
    </a-modal>

  </div>
</template>
<script>
import { getOrderData, getCompany, getGroup, getCounselor, exportOrderrdata, changeCustomerTypeNew, biOrderBaseMidshareOrder, getUser } from '../../server/orderList'
import { mapGetters } from 'vuex'
import fileDownload from 'js-file-download' // 导出文件插件
import moment from 'moment'

export default {
  name: 'OrderList',
  data() {
    return {
      visibleUpdateOrderType: false,  //
      visibleUpdateOrderSharing: false,  // 订单分成
      buttonLoading: false,
      insetData: { //   搜索框model默认数据
        createTime: undefined, // 创建时间
        commitTime: undefined, // 支付时间
        payTime: undefined, // 支付时间
        orderChannel: undefined, // 订单渠道
        ordercode: undefined, // 订单号
        paycode: undefined, // 合同号
        bname: undefined, // 业务主体名称
        customerType: undefined, // 客户类型
        customerTypeNew: undefined, // 订单类型（薪酬）
        registerTime: undefined, // 注册时间
        successTime: undefined, // 首次成交日期
        threeType: undefined, // 产品名称
        filialeType: undefined, // 顾问区域分公司
        groupeType: undefined, // 顾问组别
        counselorType: undefined, // 顾问姓名
        refundIs: undefined, // 是否退款
        refundTime: undefined, // 退款时间
        filingTime: undefined // 退款时间
      },
      dateFormat: 'YYYY-MM-DD', // 日期显示格式
      // 表格数据
      tableData: [],
      pageTotal: 0, // 数据数量
      loadingData: true, // 是否加载
      tableObject: { // 搜索传参数据
        page: 1,
        rows: 10,
        condition: {
          sysId: ''
        }
      },
      // 表头
      columns: [
        {
          title: '用户ID',
          dataIndex: 'uid',
          key: 'uid',
          width: 100,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '首次成交日期',
          dataIndex: 'firstPaytime',
          key: 'firstPaytime',
          align: 'center'
        },
        {
          title: '客户类型',
          dataIndex: 'customerType',
          key: 'customerType',
          align: 'center',
          ellipsis: true
        },

        {
          title: '订单类型（薪酬）',
          dataIndex: 'customerTypeNew',
          key: 'customerTypeNew',
          align: 'center',
          ellipsis: true
        },

        {
          title: '订单号',
          dataIndex: 'ordercode',
          key: 'ordercode',
          align: 'center',
          ellipsis: false
        },
        {
          title: '合同号',
          dataIndex: 'paycode',
          key: 'paycode',
          align: 'center',
          ellipsis: false
        },
        // {
        //   title: '产品代码',
        //   dataIndex: 'pcode',
        //   key: 'pcode',
        //   align: 'center',
        //   ellipsis: false
        // },
        {
          title: '产品名称',
          dataIndex: 'pname',
          key: 'pname',
          align: 'center',
          ellipsis: false
        },
        {
          title: '是否退款',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          ellipsis: false
        },
        {
          title: '订单金额',
          dataIndex: 'money',
          key: 'money',
          align: 'center',
          ellipsis: false
        },
        // {
        //   title: '优惠金额',
        //   dataIndex: 'cdkmoney',
        //   key: 'cdkmoney',
        //   align: 'center',
        //   ellipsis: false
        // },
        {
          title: '官费',
          dataIndex: 'publicexp',
          key: 'publicexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '服务费',
          dataIndex: 'realserveexp',
          key: 'realserveexp',
          align: 'center',
          ellipsis: false
        },
        // {
        //   title: '业务线收入',
        //   dataIndex: 'businessMoney',
        //   key: 'businessMoney',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '流程收入',
        //   dataIndex: 'processMoney',
        //   key: 'processMoney',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '集团预留',
        //   dataIndex: 'groupMoney',
        //   key: 'groupMoney',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '保险收入',
        //   dataIndex: 'insureIncomeMoney',
        //   key: 'insureIncomeMoney',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '保险成本',
        //   dataIndex: 'inseAmt',
        //   key: 'inseAmt + index',
        //   align: 'center',
        //   ellipsis: false
        // },
        {
          title: '贡献值',
          dataIndex: 'branchMoney',
          key: 'branchMoney',
          align: 'center',
          ellipsis: false
        },
        // {
        //   title: '渠道费',
        //   dataIndex: 'channelMoney',
        //   key: 'channelMoney',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '第三方成本',
        //   dataIndex: 'costexp',
        //   key: 'costexp',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '保险费',
        //   dataIndex: 'inseAmt',
        //   key: 'inseAmt',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '保险类型',
        //   dataIndex: 'inseName',
        //   key: 'inseName',
        //   align: 'center',
        //   ellipsis: false,
        //   defaultFilteredValue: '-'
        // },
        // {
        //   title: '小项数',
        //   dataIndex: 'category',
        //   key: 'category',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '订单所选类别',
        //   dataIndex: 'tcategory',
        //   key: 'tcategory',
        //   align: 'center',
        //   ellipsis: false
        // },
        {
          title: '创建时间',
          dataIndex: 'addtime',
          key: 'addtime',
          align: 'center',
          ellipsis: false
        },

        {
          title: '立案时间',
          dataIndex: 'filingTime',
          key: 'filingTime',
          align: 'center',
          ellipsis: false
        },

        {
          title: '支付时间',
          dataIndex: 'paytime',
          key: 'paytime',
          align: 'center',
          ellipsis: false
        },
        {
          title: '组ID',
          dataIndex: 'gwGroupId',
          key: 'gwGroupId',
          align: 'center',
          ellipsis: false
        },
        {
          title: '顾问姓名',
          dataIndex: 'gwRealname',
          key: 'gwRealname',
          align: 'center',
          ellipsis: false
        },
        {
          title: '顾问昵称',
          dataIndex: 'gwNickname',
          key: 'gwNickname',
          align: 'center',
          ellipsis: false
        },
        {
          title: '成单顾问',
          dataIndex: 'realname',
          key: 'realname',
          align: 'center',
          ellipsis: false
        },
        // {
        //   title: '订单渠道',
        //   dataIndex: 'orderChannel',
        //   key: 'orderChannel',
        //   align: 'center',
        //   ellipsis: false
        // },
        {
          title: '业务主体名称',
          dataIndex: 'bname',
          key: 'bname',
          align: 'center',
          ellipsis: false
        },
        // {
        //   title: '注册时间',
        //   dataIndex: 'regtime',
        //   key: 'regtime',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '用户注册渠道',
        //   dataIndex: 'regChannel',
        //   key: 'regChannel',
        //   align: 'center',
        //   ellipsis: false
        // },
        {
          title: '顾问组别',
          dataIndex: 'gwGroupName',
          key: 'gwGroupName',
          align: 'center',
          ellipsis: false
        },
        {
          title: '顾问区域/分公司',
          dataIndex: 'gwUpperGrpName',
          key: 'gwUpperGrpName',
          align: 'center',
          ellipsis: false
        },
        // {
        //   title: '产品一级分类',
        //   dataIndex: 'pTopCategory',
        //   key: 'pTopCategory',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '产品二级分类',
        //   dataIndex: 'pSubCategory',
        //   key: 'pSubCategory',
        //   align: 'center',
        //   ellipsis: false
        // },
        // {
        //   title: '税费',
        //   dataIndex: 'billmoney',
        //   key: 'billmoney',
        //   align: 'center',
        //   ellipsis: false
        // },
        {
          title: '退款时间',
          dataIndex: 'refundtime',
          key: 'refundtime',
          align: 'center'
        },

        {
          title: '分成顾问',
          dataIndex: 'shareGwName',
          key: 'shareGwName',
          align: 'center'
        },

        {
          title: '分成顾问组别',
          dataIndex: 'shareGwGroupName',
          key: 'shareGwGroupName',
          align: 'center'
        },

        {
          title: '分成顾问分公司',
          dataIndex: 'shareGwUpperGrpName',
          key: 'shareGwUpperGrpName',
          align: 'center'
        },

        {
          title: '分成比例',
          dataIndex: 'shareScale',
          key: 'shareScale',
          align: 'center'
        },

        {
          title: '分成业绩',
          dataIndex: 'shareRealserveexpMoney',
          key: 'shareRealserveexpMoney',
          align: 'center'
        },

        {
          title: '分成利润业绩',
          dataIndex: 'shareMoney',
          key: 'shareMoney',
          align: 'center'
        }

      ],
      companyArr: [], // 分公司列表数据
      groupArr: [], // 分组列表数据
      provinceData: [], // 省数据
      counselorArr: [], // 顾问列表数据
      companyName: '', // 分公司名称
      groupNameId: '', // 分组id
      selectedRowKeys: [],
      selectedRows: [],
      gwList: [], // 顾问列表
      updateFormOrderType: {
        ids: undefined,
        shareGwId: undefined, // 顾问id
        shareGwName: undefined,  // 顾问名称
        shareScale: undefined,  // 分成比例
        customerTypeNew: undefined,
        layout: 'horizontal'
      },
      rowSelection: {
        onChange: this.onChange,
        selectedRowKeys: [],
        selectedRows: []
      }

    }
  },
  computed: {
    ...mapGetters(['userInfo']),

    formItemLayout() {
      const { layout } = this.updateFormOrderType;
      return layout === 'horizontal'
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 8 }
        }
        : {};
    },

    // 获取当前月第一天
    getCurrentMonthFirst() {
      const date = new Date()
      date.setDate(1)
      const seperator1 = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      const currentdate = year + seperator1 + month + seperator1 + strDate
      return currentdate
    },
    // 获取当前日期
    getNowFormatDate() {
      const date = new Date()
      const seperator1 = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      const currentdate = year + seperator1 + month + seperator1 + strDate
      return currentdate
    }

    // rowSelection() {
    //   return {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //       this.selectedRowKeys = selectedRowKeys;
    //       this.selectedRows = selectedRows

    //       console.log('this.selectedRowKeys', this.selectedRowKeys)

    //       console.log('this.selectedRows', this.selectedRows)
    //     },
    //     fixed: true,
    //     getCheckboxProps: record => ({
    //       props: {
    //         disabled: false
    //         // defaultChecked: this.selectedRowKeys.includes(record.orderCode)
    //       }
    //     })
    //   }
    // }

  },
  created() {
    this.tableObject.condition.sysId = this.userInfo.id
    this.tableObject.condition.tenantId = this.userInfo.tenantId
    this.tableObject.condition.paytimeS = this.getCurrentMonthFirst
    this.tableObject.condition.paytimeE = this.getNowFormatDate
    if (this.tableObject.condition.sysId) {
      // 进入页面默认调一次表格接口
      this.getOrderDatas(this.tableObject)
    }
  },

  mounted() {
    this.getUser()
  },

  methods: {
    moment,

    // 所有用户
    getUser () {
      this.gwList = [];
      getUser().then((res) => {
        console.log('用户列表--------', res)
        if (res.data.code === '0') {
          if (res.data.data) {
            if (res.data.data.length > 0) {
              res.data.data.forEach(item => {
                this.gwList.push({
                  userid: item.id,
                  username: item.realname
                })
              })

              console.log('this.gwList-------', this.gwList)
            }
          } else {
            console.log('无数据')
          }
        } else {
          console.log('res.message', res.message)
        }
      }).catch((e) => {
        console.log('res.message', e)
      });
    },

    // 订单分成
    submitOrderSharing() {
      this.updateFormOrderType.ids = this.rowSelection.selectedRowKeys // id

      if (this.updateFormOrderType.shareGwId) {
        const userObject = this.gwList.find(item => Number(item.userid) === Number(this.updateFormOrderType.shareGwId))

        if (userObject) {
          this.updateFormOrderType.shareGwName = userObject.username
        } else {
          this.updateFormOrderType.shareGwName = ''
        }
      }

      if (!this.updateFormOrderType.shareGwId) {
        this.$message.error('请选择分成顾问');
        return false;
      }

      if (!this.updateFormOrderType.shareScale) {
        this.$message.error('请选择分成比例');
        return false;
      }

      if ((this.updateFormOrderType.ids).length > 0) {
        const params = JSON.parse(JSON.stringify(this.updateFormOrderType));

        delete params.layout

        console.log('params-----', params)

        biOrderBaseMidshareOrder(params).then((res) => {
          console.log('res', res.data)

          if (Number(res.data.code) === 0) {
            this.$message.success('操作成功');
            this.updateFormOrderType.ids = []
            this.updateFormOrderType.customerTypeNew = undefined

            this.updateFormOrderType.shareGwId = undefined // 顾问id
            this.updateFormOrderType.shareGwName = undefined  // 顾问名称
            this.updateFormOrderType.shareScale = undefined  // 分成比例

            this.rowSelection.selectedRowKeys = []
            console.log('rowSelection', this.rowSelection)

            this.visibleUpdateOrderSharing = false

            this.inquireClick('1')
          } else {
            this.$message.error('操作失败');
          }
        }).catch((e) => {
          console.log(e)
        });
      } else {
        this.$message.error('请先选择数据');
        return false;
      }
    },

    // 复选框 change
    onChange (selectedRowKeys, selectedRows) {
      this.rowSelection.selectedRowKeys = selectedRowKeys
    },

    onUpdateOrderType() {
      this.updateFormOrderType.ids = this.rowSelection.selectedRowKeys // 订单id

      if ((this.updateFormOrderType.ids).length > 0) {
        const params = JSON.parse(JSON.stringify(this.updateFormOrderType));
        delete params.layout
        changeCustomerTypeNew(params).then((res) => {
          console.log('res', res.data)

          if (Number(res.data.code) === 0) {
            this.$message.success('操作成功');
            this.updateFormOrderType.ids = []
            this.updateFormOrderType.customerTypeNew = undefined

            this.rowSelection.selectedRowKeys = []
            console.log('rowSelection', this.rowSelection)

            this.visibleUpdateOrderType = false

            this.inquireClick('1')
          } else {
            this.$message.error('操作失败');
          }
        }).catch((e) => {
          console.log(e)
        });
      } else {
        this.$message.error('请先选择数据');
        return false;
      }
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    updateOrderType() {
      this.visibleUpdateOrderType = true
    },

    orderSharing() {
      this.visibleUpdateOrderSharing = true
    },

    // 表格数据
    getOrderDatas(val, type) {
      this.loadingData = true
      getOrderData(val).then(res => {
        if (res) {
          this.tableData = res.data.page.list
          this.pageTotal = res.data.page.total
          // 如果是查询数据
          if (type === '1') {
            this.$message.success('查询成功')
          } else if (type === '2') { // 重置时
            this.$message.info('表格数据已初始化')
          }
          // 表格数据转换
          if (this.tableData) {
            this.tableData.map(i => {
              // 是否退款（1 是 2 否）
              if (i.status === 1) {
                i.status = '已支付'
              } else if (i.status === -2 || i.status === -1) {
                i.status = '已退款'
              }
            })
          }
        }
      }).then(() => {
        this.loadingData = false
      }).catch(err => {
        console.log(err, '表格数据')
      })
    },
    // 创建时间change事件
    createChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.addtimeS = dateString[0]
        this.tableObject.condition.addtimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.addtimeS
        delete this.tableObject.condition.addtimeE
      }
    },
    // 报件时间change事件
    commitChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.committimeS = dateString[0]
        this.tableObject.condition.committimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        // this.tableObject.condition.paytimeS = this.getCurrentMonthFirst
        // this.tableObject.condition.paytimeE = this.getNowFormatDate
        delete this.tableObject.condition.committimeS
        delete this.tableObject.condition.committimeE
      }
    },
    // 支付时间change事件
    payChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.paytimeS = dateString[0]
        this.tableObject.condition.paytimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        // this.tableObject.condition.paytimeS = this.getCurrentMonthFirst
        // this.tableObject.condition.paytimeE = this.getNowFormatDate
        delete this.tableObject.condition.paytimeS
        delete this.tableObject.condition.paytimeE
      }
    },
    // 不可选择的日期控制
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    // 订单渠道change事件
    orderChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.orderChannel = val
      } else {
        delete this.tableObject.condition.orderChannel
      }
    },
    // 订单号change事件
    orderCodeChange(val) {
      if (val.target.value !== '') {
        this.tableObject.condition.ordercode = val.target.value
      } else {
        delete this.tableObject.condition.ordercode
      }
    },
    // 合同号change事件
    payCodeChange(val) {
      if (val.target.value !== '') {
        this.tableObject.condition.paycode = val.target.value
      } else {
        delete this.tableObject.condition.paycode
      }
    },
    // 业务主体change事件
    bnameChange(val) {
      if (val.target.value !== '') {
        this.tableObject.condition.bname = val.target.value
      } else {
        delete this.tableObject.condition.bname
      }
    },
    // 产品名称分类change事件
    productChange(val) {
      if (val.target.value !== '') {
        this.tableObject.condition.pname = val.target.value
      } else {
        delete this.tableObject.condition.pname
      }
    },
    // 客户类型change事件
    customerChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.customerType = val
      } else {
        delete this.tableObject.condition.customerType
      }
    },
    // 注册时间change事件
    registerChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.regtimeS = dateString[0]
        this.tableObject.condition.regtimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.regtimeS
        delete this.tableObject.condition.regtimeE
      }
    },
    // 首次成交日期change事件
    firstChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.firstPaytimeS = dateString[0]
        this.tableObject.condition.firstPaytimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.firstPaytimeS
        delete this.tableObject.condition.firstPaytimeE
      }
    },
    // 顾问区域分公司change事件
    filialeChange(val) {
      if (val !== undefined) {
        this.companyName = val // 获取公司名称参数
        this.tableObject.condition.company = val
        // 获取分组列表
        const groups = {
          sysId: this.userInfo.id,
          company: this.companyName
        }
        getGroup(groups).then(res => {
          if (res) {
            this.groupArr = res.data.groupList
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        this.groupArr = []
        this.counselorArr = []
        this.insetData.groupeType = undefined
        this.insetData.counselorType = undefined
        delete this.tableObject.condition.company
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
      }
    },
    // 顾问区域分公司获得焦点事件
    filialeFocus() {
      // 获取分公司列表
      const datas = {
        sysId: this.userInfo.id
      }
      getCompany(datas).then(res => {
        if (res) {
          this.companyArr = res.data.companyList
        }
      }).catch((err) => {
        console.log(err, '分公司')
      })
    },
    // 顾问组别change事件
    groupeChange(val) {
      if (val !== undefined) {
        this.groupNameId = val
        this.tableObject.condition.groupId = val
        // 获取顾问列表
        const counselors = {
          sysId: this.userInfo.id,
          groupId: this.groupNameId
        }
        getCounselor(counselors).then(res => {
          if (res) {
            this.counselorArr = res.data.gwList
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        this.counselorArr = []
        this.insetData.counselorType = undefined
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
      }
    },

    // 顾问姓名change事件
    counselorChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.sysPramId = val // 赋值顾问id
      } else {
        delete this.tableObject.condition.sysPramId
      }
    },
    // 是否退款change事件
    refundChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.status = val
      } else {
        delete this.tableObject.condition.status
      }
    },
    // 退款时间change事件
    refundTimeChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.refundtimeS = dateString[0]
        this.tableObject.condition.refundtimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.refundtimeS
        delete this.tableObject.condition.refundtimeE
      }
    },

    // 立案时间change事件
    filingTimeChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.filingTimeS = dateString[0]
        this.tableObject.condition.filingTimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.filingTimeS
        delete this.tableObject.condition.filingTimeE
      }
    },

    // 查询按钮
    inquireClick(val) {
      if (val === '1') {
        this.getOrderDatas(this.tableObject, '1')
      }
    },
    // 导出按钮
    exportClick(val) {
      if (val === '2') {
        const fileDownData = { ...this.tableObject }
        exportOrderrdata(fileDownData).then(res => {
          if (res.code) {
            this.$message.error(res.message)
          } else {
            const now = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            // console.log(now)
            fileDownload(res.data, '订单明细' + now + '.xls')
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    // 重置按钮
    insetClick(val) {
      if (val === '3') {
        this.tableObject = {
          page: 1,
          rows: 10,
          condition: {
            sysId: this.userInfo.id,
            paytimeS: this.getCurrentMonthFirst,
            paytimeE: this.getNowFormatDate
          }
        }
        this.getOrderDatas(this.tableObject, '2')
        // 重置搜索框
        this.insetData = {
          createTime: undefined, // 创建时间
          commitTime: undefined, // 支付时间
          payTime: undefined, // 支付时间
          orderChannel: undefined, // 订单渠道
          ordercode: undefined, // 订单号
          paycode: undefined, // 合同号
          bname: undefined, // 业务主体名称
          customerType: undefined, // 客户类型
          registerTime: undefined, // 注册时间
          successTime: undefined, // 首次成交日期
          threeType: undefined, // 产品名称
          filialeType: undefined, // 顾问区域分公司
          groupeType: undefined, // 顾问组别
          counselorType: undefined, // 顾问姓名
          refundIs: undefined, // 是否退款
          refundTime: undefined // 退款时间
        }
        this.provinceData = [] // 省数组清空
        this.groupArr = [] // 组别数组清空
        this.counselorArr = [] // 顾问姓名数组清空
      }
    },
    // 分页change
    pageChange(page, pageSize) {
      if (page && pageSize) {
        this.tableObject.page = page
        this.tableObject.rows = pageSize
        this.getOrderDatas(this.tableObject)
      }
    },
    // 显示数据量change
    pageSizePage(current, size) {
      if (current && size) {
        this.tableObject.page = current
        this.tableObject.rows = size
        this.getOrderDatas(this.tableObject)
      }
    }
  }
};
</script>
<style scoped lang="scss">
  .pageClass {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    height: 80px;
  }
  .pageClass>.ant-pagination {
    position: absolute;
    right: 0;
    top: 10px;
  }
</style>
